@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _multi-select-vars($settings);
    @include _themes(map_get_strict($settings, "themes"));
  }
}

@mixin _multi-select-vars($settings) {
  --multi-select-padding: #{map_get_strict($settings, "padding")};
  --multi-select-placeholder-padding: #{map_get_strict($settings, "placeholder-padding")};
  --multi-select-search-margin: #{map_get_strict($settings, "search-margin")};
  --multi-select-search-width: #{map_get_strict($settings, "search-width")};
}

@mixin _themes($settings) {
  @include _multi-select-fluid-theme-vars(map_get_strict($settings, "fluid"));
}

@mixin _multi-select-fluid-theme-vars($settings) {
  --multi-select-fluid-padding: #{map_get_strict($settings, "padding")};
  --multi-select-fluid-placeholder-padding: #{map_get_strict($settings, "placeholder-padding")};
  --multi-select-fluid-tag-margin: #{map_get_strict($settings, "tag-margin")};
}
