@use "shared/theming-mixins/select";
@use "shared/mixins/invisible" as *;
@use "shared/mixins/reset-button" as *;
@use "shared/mixins/overflow-ellipsis" as *;
@use "shared/mixins/forced-scrollbar" as *;
@use "shared/mixins/media-queries" as *;

@mixin select {
  align-items: center;
  background: var(--select-background);
  border: var(--select-border);
  border-radius: var(--select-border-radius);
  color: var(--select-color);
  cursor: var(--select-cursor);
  display: flex;
  min-height: var(--select-min-height);
  min-width: var(--select-min-width);
  padding: var(--select-padding);
  position: relative;
  transition: var(--select-transition);

  &_focused {
    border-color: var(--select-focused-border-color);
    box-shadow: var(--select-focused-box-shadow);
  }

  &_invalid {
    border-color: var(--select-invalid-border-color);
  }

  &_disabled {
    background: var(--select-disabled-background);
    border-color: var(--select-disabled-border-color);
    color: var(--select-disabled-color);
    cursor: var(--select-disabled-cursor);
  }

  &_opened {
    border-radius: var(--select-opened-border-radius);
  }

  &_multiple {
    padding-bottom: 6px;
    padding-left: 7px;
    padding-top: 6px;
  }

  &__focusholder {
    @include invisible;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    min-width: 0;
  }

  &__clear {
    @include reset-button;

    color: var(--select-icon-color);
    cursor: pointer;
    flex: 0 0 auto;
    line-height: 0;
    margin-top: 1px;
    padding: 3px 7px;

    &_within-item {
      margin: 0 -3px 0 -7px;
    }

    .select_disabled & {
      display: none;
    }
  }

  &__arrow {
    color: var(--select-icon-color);
    cursor: pointer;
    flex: 0 0 auto;
    margin-top: 2px;
    padding: 7px;
    transform-origin: center center;
    transition:
      transform 250ms ease,
      color 250ms ease;

    .select_opened & {
      transform: rotate(180deg);
    }

    .select_disabled & {
      display: none;
    }
  }

  &__label {
    padding-left: 7px;
  }

  &__value {
    @include overflow-ellipsis;

    align-items: center;
    cursor: pointer;
    display: var(--select-value-display);
    flex: 1 1 auto;
    line-height: 20px;
    padding: var(--select-value-padding, unset);

    &_placeholder {
      color: var(--select-value-placeholder-color);
    }

    &_disabled {
      color: var(--select-value-disabled-color);
      cursor: var(--select-value-disabled-cursor);
    }
  }

  &__spinner {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
  }

  &__filter {
    background: transparent;
    border: none;
    flex: 1 1 auto;
    line-height: 20px;
    margin: 0;
    min-width: 0;
    outline: none;
    padding: var(--select-value-filter-padding, unset);
  }

  &_multiple &__value {
    padding: 3px 0 3px 13px;
  }

  &_multiple &__filter {
    flex: 1 1 auto;
    height: auto;
    margin: 3px;
    min-height: 20px;
    min-width: 70px;
    outline: none;
    padding: 0;
    width: auto;
  }

  &__selected-item-title {
    display: block;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__selected-item {
    align-items: baseline;
    background: var(--select-selected-item-background);
    border-radius: 14px;
    color: var(--select-selected-item-color);
    cursor: default;
    display: inline-flex;
    margin: 3px 4px;
    max-width: 100%;
    min-height: 20px;
    padding: 0 7px;
    transition: var(--select-selected-item-transtition);
  }

  &_disabled &__selected-item {
    opacity: 0.5;
    pointer-events: none;
  }

  &__dropdown {
    border: 1px solid var(--select-dropdown-element-border-color);
    border-radius: var(--select-dropdown-element-border-radius);
    border-top-color: var(--select-dropdown-element-border-top-color);
    box-shadow: var(--select-dropdown-element-box-shadow);
    display: block;
    margin: var(--select-dropdown-element-margin);
    overflow: hidden;

    &_flipped {
      border-bottom-color: var(--select-dropdown-element-border-top-color);
      border-radius: var(--select-dropdown-element-flipped-border-radius);
      border-top-color: var(--select-dropdown-element-border-color);
    }
  }
}

@mixin select-fluid-theme($block-name, $default-state: accent) {
  border-radius: var(--select-fluid-border-radius);
  box-shadow: none;
  cursor: pointer;
  min-height: var(--select-fluid-min-height);
  min-width: 80px;
  padding: 0;

  &.#{$block-name}_empty:not(.#{$block-name}_disabled) {
    border-color: var(--select-fluid-empty-border-color);
    color: var(--select-fluid-empty-color);

    @if $default-state == accent {
      font-weight: 600;
    }

    &:hover {
      background: var(--select-fluid-empty-hover-background);
      border-color: var(--select-fluid-empty-hover-border-color);
      color: var(--select-fluid-empty-hover-color);
    }

    &.#{$block-name}_focused {
      background: var(--select-fluid-empty-focused-background);
      border-color: var(--select-fluid-empty-focused-border-color);
      color: var(--select-fluid-empty-focused-color);
    }

    &.#{$block-name}_focused:hover {
      border-color: var(--select-fluid-empty-focused-hover-border-color);
    }
  }

  &:not(.#{$block-name}_empty):not(.#{$block-name}_disabled) {
    @if $default-state == accent {
      border-color: var(--select-fluid-accent-border-color);
      color: var(--select-fluid-accent-color);
    }

    &:hover {
      background: var(--select-fluid-hover-background);
      border-color: var(--select-fluid-hover-border-color);
    }

    &.#{$block-name}_focused {
      background: var(--select-fluid-focused-background);
      border-color: var(--select-fluid-focused-border-color);
    }

    &.#{$block-name}_focused:hover {
      border-color: var(--select-fluid-focused-hover-border-color);
    }
  }

  &.#{$block-name}_invalid:not(.#{$block-name}_disabled) {
    border-color: var(--select-fluid-invalid-border-color);

    &:hover {
      background: var(--select-fluid-invalid-hover-background);
      border-color: var(--select-fluid-invalid-hover-border-color);
    }

    &.#{$block-name}_focused {
      background: var(--select-fluid-invalid-focused-background);
      border-color: var(--select-fluid-invalid-focused-border-color);
    }

    &.#{$block-name}_focused:hover {
      border-color: var(--select-fluid-invalid-focused-hover-border-color);
    }
  }

  .#{$block-name}__value {
    align-items: center;
    display: flex;
    text-overflow: initial;

    &_placeholder {
      color: inherit;
      padding-left: 7px;

      &:last-child {
        padding-right: 7px;
      }
    }
  }

  .#{$block-name}__arrow {
    color: inherit;
  }

  &.#{$block-name}_disabled .#{$block-name}__arrow {
    color: var(--select-fluid-disabled-arrow-color);
    cursor: not-allowed;
    display: inline-block;
  }

  .#{$block-name}__selected-item {
    background: var(--select-fluid-selected-item-background);
    border-radius: 4px;
    color: var(--select-fluid-selected-item-color);
    cursor: pointer;
    font-weight: 600;
    line-height: 20px;
    margin: 3px;
    max-width: 20vw;
    padding: 2px 6px;

    @include device(phablet) {
      max-width: 50vw;
    }

    &:hover {
      background: var(--select-fluid-selected-item-hover-background);
      color: var(--select-fluid-selected-item-hover-color);
    }
  }

  .#{$block-name}__clear_within-item {
    color: inherit;
    margin: 0 -7px 0 -3px;
  }
}

@mixin select-dropdown-fluid-theme($max-width: 288px, $icon-size: 28px) {
  border: var(--select-fluid-dropdown-element-border);
  border-radius: var(--select-fluid-dropdown-element-border-radius);
  box-shadow: var(--select-fluid-dropdown-element-box-shadow);
  margin: var(--select-fluid-dropdown-element-margin);
  max-width: $max-width;

  .select-dropdown {
    border-radius: var(--select-fluid-dropdown-border-radius);
    max-height: 276px;

    &__content {
      @include forced-scrollbar(
        var(--select-fluid-dropdown-scrollbar-color),
        var(--select-fluid-dropdown-scrollbar-width),
        var(--select-fluid-dropdown-scrollbar-hover-color),
        var(--select-fluid-dropdown-scrollbar-height)
      );
    }

    &__item {
      border: none;
      border-radius: var(--select-fluid-dropdown-item-border-radius);
      margin: 0 8px;
      padding: 8px 16px 8px 8px;

      &_selected {
        color: var(--select-fluid-dropdown-item-selected-color);
        font-weight: 600;
      }

      &_disabled {
        background: transparent;
      }
    }

    &__item-label {
      color: inherit;
      flex: 1 1 auto;
      min-width: 0;
    }

    &__item-icon {
      border-radius: var(--select-fluid-dropdown-item-icon-border-radius);
      height: $icon-size;
      margin-right: 8px;
      width: $icon-size;
    }

    &__item-content-wrapper {
      overflow: hidden;
    }

    &__item-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__items-group {
      border-top: var(--select-fluid-dropdown-items-group-border-top);
      padding: 4px 16px;

      &:not(:first-child) {
        margin-top: 8px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__items {
      padding: var(--select-fluid-dropdown-items-padding);

      &_with-search,
      &_with-group {
        padding-top: 0;
      }

      &_with-search.select-dropdown__items_with-group {
        border-top: var(--select-fluid-dropdown-items-search-group-border-top);
      }
    }
  }
}

@mixin select-fluid-borderless {
  background: transparent;
  border-color: transparent;
  border-radius: var(--select-fluid-borderless-border-radius);
  box-shadow: none;
  min-height: var(--select-fluid-borderless-min-height);
  padding: 0;

  &:not(.select_disabled) {
    color: var(--select-fluid-borderless-color);

    &:hover {
      background: var(--select-fluid-borderless-hover-background);
      border-color: var(--select-fluid-borderless-hover-border-color);
      color: var(--select-fluid-borderless-hover-color);
    }

    &.select_focused {
      background: var(--select-fluid-borderless-focused-background);
      border-color: var(--select-fluid-borderless-focused-border-color);
      color: var(--select-fluid-borderless-focused-color);
    }

    &.select_focused:hover {
      border-color: var(--select-fluid-borderless-focused-hover-border-color);
    }
  }

  &__value_placeholder {
    color: var(--select-fluid-borderless-value-placeholder-color);
  }

  &__arrow {
    color: inherit;
  }
}

@mixin select-grayscale-theme {
  background: var(--select-grayscale-background);
  z-index: 1;

  &.select_focused {
    border-color: var(--select-grayscale-focused-border-color);
  }

  &.select_disabled {
    background: var(--select-grayscale-disabled-background);
    border-color: var(--select-grayscale-disabled-border-color);
    color: var(--select-grayscale-disabled-color);

    .select__value {
      color: var(--select-grayscale-disabled-color);
    }
  }
}

@mixin select-dropdown-grayscale-theme {
  border-color: var(--select-grayscale-dropdown-border-color);
}

@mixin select-error-styles($block-name) {
  &:not(.#{$block-name}_disabled) {
    border-color: var(--select-error-border-color);
  }

  &.#{$block-name}_focused {
    border-color: var(--select-error-focused-border-color);
  }
}

@mixin select-dropdown-error-styles {
  border: 1px solid var(--select-error-dropdown-border-color);
  border-top-color: var(--select-error-dropdown-border-top-color);

  &_flipped {
    border-bottom-color: var(--select-error-dropdown-border-top-color);
    border-top-color: var(--select-error-dropdown-border-color);
  }
}

@mixin select-warning-styles($block-name) {
  &:not(.#{$block-name}_disabled) {
    border-color: var(--select-warning-border-color);
  }

  &.#{$block-name}_focused {
    border-color: var(--select-warning-focused-border-color);
  }
}

@mixin select-dropdown-warning-styles {
  border: 1px solid var(--select-warning-dropdown-border-color);
  border-top-color: var(--select-warning-dropdown-border-top-color);

  &_flipped {
    border-bottom-color: var(--select-warning-dropdown-border-top-color);
    border-top-color: var(--select-warning-dropdown-border-color);
  }
}
