@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  --date-time-input-background: #{map_get($settings, "background")};
  --date-time-input-border: #{map_get($settings, "border")};
  --date-time-input-border-radius: #{map_get($settings, "border-radius")};
  --date-time-input-color: #{map_get($settings, "color")};
  --date-time-input-transition: #{map_get($settings, "transition")};
  --date-time-input-placeholder-color: #{map_get($settings, "placeholder-color")};
  --date-time-input-disabled-background: #{map_get($settings, "disabled-background")};
  --date-time-input-focused-border-color: #{map_get($settings, "focused-border-color")};
  --date-time-input-invalid-background: #{map_get($settings, "invalid-background")};
  --date-time-input-invalid-border-color: #{map_get($settings, "invalid-border-color")};
  --date-time-input-invalid-and-focused-background: #{map_get($settings, "invalid-and-focused-background")};
  --date-time-input-invalid-and-focused-border-color: #{map_get($settings, "invalid-and-focused-border-color")};
  --date-time-input-invalid-and-focused-placeholder-color: #{map_get(
      $settings,
      "invalid-and-focused-placeholder-color"
    )};
}
