@use "shared/theming-mixins/select";

.select-dropdown {
  $block: &;

  background: var(--select-dropdown-background);
  display: flex;
  flex-direction: column;
  max-height: var(--select-dropdown-max-height);
  z-index: 1;

  &__content {
    flex: 1;
    overflow: hidden auto;
    -webkit-overflow-scrolling: touch;
  }

  &__header,
  &__footer {
    flex: 0;
  }

  &__items {
    padding: var(--select-dropdown-items-padding);

    &_with-search {
      padding-top: 0;
    }
  }

  &__item {
    align-items: center;
    border-bottom: var(--select-dropdown-item-border-bottom);
    border-radius: var(--select-dropdown-item-border-radius);
    color: var(--select-dropdown-item-color);
    cursor: pointer;
    display: flex;
    margin: var(--select-dropdown-item-margin);
    padding: var(--select-dropdown-item-padding);
    position: relative;

    &:not(&_inherit-border):last-child {
      border: none;
    }

    &_inherit-border {
      border: inherit;
    }

    &_focused:not(&_disabled) {
      background: var(--select-dropdown-item-focused-background);
    }

    &,
    &:focus,
    &:hover {
      outline: none;
    }

    &_disabled {
      background: var(--select-dropdown-item-disabled-background);
      color: var(--select-dropdown-item-disabled-color);
      cursor: default;
    }

    &_empty {
      cursor: default;
      font-style: italic;
      padding: 12px;
    }

    &_selected {
      color: var(--select-dropdown-item-selected-color);
      font-weight: 600;
    }

    &_connection-picker {
      padding-right: 103px;
    }
  }

  &__item-recommended-marker {
    background: var(--select-dropdown-item-recommended-marker-background);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 6px;
  }

  &__item-label {
    color: var(--select-dropdown-item-label-color);
    flex: 1 1 auto;
    line-height: 1.3;
    min-width: 0;

    &_icon {
      align-items: center;
      display: flex;
    }

    #{$block}__item_disabled & {
      color: inherit;
    }
  }

  &__item-icon {
    align-items: center;
    border-radius: 50%;
    color: var(--select-dropdown-item-icon-color);
    display: flex;
    height: 35px;
    justify-content: center;
    margin-right: 12px;
    width: 35px;

    &_svg {
      border-radius: 0;
    }

    &_square {
      border-radius: 2px;
    }
  }

  &__items-group {
    background: var(--select-dropdown-items-group-background);
    border-bottom: var(--select-dropdown-items-group-border-bottom);
    border-top: var(--select-dropdown-items-group-border-top);
    color: var(--select-dropdown-items-group-color);
    cursor: default;
    font-size: 12px;
    margin: var(--select-dropdown-items-group-margin);
    padding: var(--select-dropdown-items-group-padding);

    &:first-child {
      margin-top: 0;
    }
  }

  &__checkbox {
    display: flex;
    margin: var(--select-dropdown-item-checkbox-margin);
    width: 14px;

    #{$block}__item_disabled & {
      cursor: default;
      opacity: 0.5;
    }
  }

  &__checkbox-input[type="checkbox"] {
    cursor: pointer;
    height: 14px;
    margin: 0;
    width: 14px;
  }

  &__item-hint {
    color: var(--select-dropdown-item-hint-color);
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__item-highlight {
    color: var(--select-dropdown-item-highlight-color);
    font-weight: var(--select-dropdown-item-highlight-font-weight);
  }

  &__item-invalid-mark {
    color: var(--select-dropdown-item-invalid-mark-color);
    font-weight: 400;
  }

  &__search-field {
    background: var(--select-dropdown-search-field-background);
    border-bottom: var(--select-dropdown-search-field-border-bottom);
    display: block;
    padding: 8px;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
